// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from 'jquery';
global.$ = $;
global.jQuery = $;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('popper.js/dist/umd/popper')
require('bootstrap')
require('bootstrap-datepicker')
require('select2')
require('moment')

global.Cropper = require('cropperjs')
global.FullCalendar = require("@fullcalendar/core");
global.dayGrid = require("@fullcalendar/daygrid");